// EditarFuncionario.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  ConteudoForm,
  ConteudoTitulo,
  Titulo,
  BotaoAcao,
  ButtonInfo,
  AlertSuccess,
  AlertDanger,
  Form,
  Label,
  Input,
  Select,
  Option, 
  ButtonWarning
} from './styles';

export const EditarFuncionario = (props) => {
  const [id] = useState(props.match.params.id);
  const [nome, setNome] = useState('');
  const [cpf, setCpf] = useState('');
  const [departamento, setDepartamento] = useState('');
  const [statusbd, setStatusbd] = useState('');

  const [status, setStatus] = useState({
    type: '',
    mensagem: ''
  });

  const editFuncionario = async (e) => {
    e.preventDefault();

    await fetch("https://timecherie.com.br/api/componentes_funcionario/editar.php", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ id, nome, cpf, departamento, statusbd })
    })
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.erro) {
          setStatus({
            type: 'error',
            mensagem: responseJson.mensagem
          });
        } else {
          setStatus({
            type: 'success',
            mensagem: responseJson.mensagem
          });
        }
      })
      .catch(() => {
        setStatus({
          type: 'error',
          mensagem: 'Funcionário não editado com sucesso, tente mais tarde!'
        });
      });
  };

  useEffect(() => {
    const getFuncionario = async () => {
      await fetch("https://timecherie.com.br/api/componentes_funcionario/visualizar.php?id=" + id)
        .then((response) => response.json())
        .then((responseJson) => {
          setNome(responseJson.funcionario.nome);
          setCpf(responseJson.funcionario.cpf);
          setDepartamento(responseJson.funcionario.departamento);
          setStatusbd(responseJson.funcionario.statusbd);
        });
    };
    getFuncionario();
  }, [id]);

  return (
    <Container>
      <ConteudoForm>
        <ConteudoTitulo>
          <Titulo>Editar Cadastro</Titulo>
          <BotaoAcao>
            <Link to="/homefuncionario">
              <ButtonInfo>Listar</ButtonInfo>
            </Link>
          </BotaoAcao>
        </ConteudoTitulo>

        {status.type === 'erro' ? <AlertDanger>{status.mensagem}</AlertDanger> : ""}
        {status.type === 'success' ? <AlertSuccess>{status.mensagem}</AlertSuccess> : ""}

        <Form onSubmit={editFuncionario}>
          <Label>Nome: </Label>
          <Input type="text" name="nome" value={nome} onChange={(e) => setNome(e.target.value)} />

          <Label>CPF: </Label>
          <Input type="text" name="cpf" value={cpf} onChange={(e) => setCpf(e.target.value)} />

          <Label>Departamento: </Label>
          <Input type="text" name="departamento" value={departamento} onChange={(e) => setDepartamento(e.target.value)} />

          <Label>Status: </Label>
          <Select name="statusbd" value={statusbd} onChange={(e) => setStatusbd(e.target.value)}>
            <Option value="1">Ativo</Option>
            <Option value="2">Inativo</Option>
          </Select>

          <ButtonWarning type="submit">Editar</ButtonWarning>
        </Form>
      </ConteudoForm>
    </Container>
  );
};
