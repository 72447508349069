import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  ConteudoForm,
  ConteudoTitulo,
  Titulo,
  BotaoAcao,
  ButtonInfo,
  AlertSuccess,
  AlertDanger,
  Form,
  Label,
  Input,
  Select,
  Option,
  ButtonSuccess
} from './styles';

export const CadastrarFuncionario = () => {
  const [funcionario, setFuncionario] = useState({
    nome: '',
    cpf: '',
    departamento: '',
    statusbd: '1' 
  });

  const [status, setStatus] = useState({
    type: '',
    mensagem: ''
  });

  const valorInput = e => setFuncionario({ ...funcionario, [e.target.name]: e.target.value });

  const cadFuncionario = async e => {
    e.preventDefault();

    try {
      const response = await fetch("https://timecherie.com.br/api/componentes_funcionario/cadastrar.php", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ funcionario })
      });

      const responseJson = await response.json();

      if (responseJson.erro) {
        setStatus({
          type: 'erro',
          mensagem: responseJson.mensagem
        });
      } else {
        setStatus({
          type: 'success',
          mensagem: responseJson.mensagem
        });
      }
    } catch (error) {
      setStatus({
        type: 'erro',
        mensagem: 'Funcionário não cadastrado com sucesso, tente mais tarde!'
      });
    }
  }

  return (
    <Container>
      <ConteudoForm>
        <ConteudoTitulo>
          <Titulo>Cadastrar Funcionário</Titulo>
          <BotaoAcao>
            <Link to="/homefuncionario">
              <ButtonInfo>Listar</ButtonInfo>
            </Link>
          </BotaoAcao>
        </ConteudoTitulo>

        {status.type === 'erro' ? <AlertDanger>{status.mensagem}</AlertDanger> : ""}
        {status.type === 'success' ? <AlertSuccess>{status.mensagem}</AlertSuccess> : ""}

        <Form onSubmit={cadFuncionario}>
          <Label>Nome: </Label>
          <Input type="text" name="nome" placeholder="Nome do Funcionário" onChange={valorInput} />

          <Label>CPF: </Label>
          <Input type="text" name="cpf" placeholder="CPF do Funcionário" onChange={valorInput} />

          <Label>Departamento: </Label>
          <Input type="text" name="departamento" placeholder="Departamento do Funcionário" onChange={valorInput} />

          <Label>Status: </Label>
          <Select name="statusbd" value={funcionario.statusbd} onChange={valorInput}>
            <Option value="1">Ativo</Option>
            <Option value="2">Inativo</Option>
          </Select>

          <ButtonSuccess type="submit">Cadastrar</ButtonSuccess>

        </Form>
      </ConteudoForm>
    </Container>
  );
}
