import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import {
  Container,
  ConteudoForm,
  ConteudoTitulo,
  Titulo,
  BotaoAcao,
  ButtonInfo,
  AlertSuccess,
  AlertDanger,
  Form,
  Label,
  Input,
  Select, 
  Option, 
  ButtonSuccess
} from './styles';

export const CadastrarEvento = () => {
  const [evento, setEvento] = useState({
    nome: '',
    descricao: '',
    data_inicio: '',
    data_fim: '',
    statusbd: '1',
    pontos: ''
  });

  const [status, setStatus] = useState({
    type: '',
    mensagem: ''
  });

  const valorInput = (e) => setEvento({ ...evento, [e.target.name]: e.target.value });

  const cadEvento = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch("https://timecherie.com.br/api/componentes_evento/cadastrar.php", {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ evento })
      });

      const responseJson = await response.json();

      if (responseJson.erro) {
        setStatus({
          type: 'erro',
          mensagem: responseJson.mensagem
        });
      } else {
        setStatus({
          type: 'success',
          mensagem: responseJson.mensagem
        });
      }
    } catch (error) {
      setStatus({
        type: 'erro',
        mensagem: 'Evento não cadastrado com sucesso, tente mais tarde!'
      });
    }
  };

  return (
    <Container>
      <ConteudoForm>
        <ConteudoTitulo>
          <Titulo>Cadastrar Evento</Titulo>
          <BotaoAcao>
            <Link to="/homeevento">
              <ButtonInfo>Listar</ButtonInfo>
            </Link>
          </BotaoAcao>
        </ConteudoTitulo>

        {status.type === 'erro' ? <AlertDanger>{status.mensagem}</AlertDanger> : ''}
        {status.type === 'success' ? <AlertSuccess>{status.mensagem}</AlertSuccess> : ''}

        <Form onSubmit={cadEvento}>
          <Label>Nome: </Label>
          <Input type="text" name="nome" placeholder="Nome do Evento" onChange={valorInput} />

          <Label>Descrição: </Label>
          <Input type="text" name="descricao" placeholder="Descrição do Evento" onChange={valorInput} />

          <Label>Data de Início: </Label>
          <Input type="date" name="data_inicio" onChange={valorInput} />

          <Label>Data de Fim: </Label>
          <Input type="date" name="data_fim" onChange={valorInput} />

          <Label>Status: </Label>
          <Select name="statusbd" value={evento.statusbd} onChange={valorInput}>
            <Option value="1">Ativo</Option>
            <Option value="2">Inativo</Option>
          </Select>
          <br></br><br></br>
          <Label>Pontos: </Label>
          <Input type="number" name="pontos" placeholder="Pontuação do Evento" onChange={valorInput} />

          <ButtonSuccess type="submit">Cadastrar</ButtonSuccess>
        </Form>
      </ConteudoForm>
    </Container>
  );
};
