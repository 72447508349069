import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './style.css';

import { Progress } from 'rsuite';
import {
    Container,
    ConteudoTitulo,
    BotaoAcao,
    ButtonSuccess,
    Table,
    Titulo,
    ButtonPrimary,
    ButtonWarning,
    ButtonDanger,
    StyledProgress
} from './styles';

export const Mobile = (props) => {
    const { usuario, cpf } = props.location.state;
    const usuarioExibicao = usuario.length > 35 ? `${usuario.substring(0, 29)}` : usuario;
    const [totalPontos, setTotalPontos] = useState(0);
    const [historicoPontos, setHistoricoPontos] = useState([]);
    const history = useHistory();

    useEffect(() => {
        fetch(`https://timecherie.com.br/api/componentes_historico/index_funcionario.php?cpf=${cpf}`)
            .then((response) => response.json())
            .then((data) => {
                const totalPontos = data.total_pontos || 0;
                setTotalPontos(totalPontos);
                const historicoPontos = data.records ? Object.values(data.records) : [];
                setHistoricoPontos(historicoPontos);
            })
            .catch((error) => {
                console.error('Erro ao obter pontos do usuário: ', error);
            });
    }, [cpf]);

    const calcularBase = () => {
        return totalPontos < 1000 ? 1000 : 2000;
    };

    const formatarData = (data) => {
        const [ano, mes, dia] = data.split('-');
        return `${dia}/${mes}/${ano}`;
    };

    // Estilos para o círculo e o texto dentro dele
    const circleStyle = {
        width: '120px',
        height: '120px',
        borderRadius: '50%',
        backgroundColor: 'transparent',
        border: '3px solid #FF69B4',
        display: 'flex',
        flexDirection: 'column', // Organiza os itens em coluna
        alignItems: 'center',
        justifyContent: 'center',
    };

    const textStyle = {
        fontSize: '44px',
        fontWeight: 'bold',
        color: '#FFFFFF',
        textAlign: 'center',
        margin: '0',
        lineHeight: '1',
    };

    // Aqui está a definição do `labelStyle`
    const labelStyle = {
        fontSize: '16px',
        color: '#FFFFFF',
        marginTop: '5px',
    };

    const baseCalculo = calcularBase();
    const progressPercentage = Math.max(((totalPontos - baseCalculo) / 10) + 100, 0);

    return (
        <div id='funcio'>
            <div className='containers'>
                <h1 className='boasV'>Bem-vindo, {usuarioExibicao}!</h1>
                <div className='circule' style={circleStyle}>
                    <p className="numpontos" style={textStyle}>{totalPontos}</p>
                    <p className="labelPontos" style={labelStyle}>Pontos</p>
                </div>

                <Table className='tabela_funcionarios'>
                    <thead>
                        <tr>
                            <th className='colu_func'>Evento / Motivo</th>
                            <th className='colu_func'>Pontos</th>
                            <th className='colu_func'>Data</th>
                        </tr>
                    </thead>
                    <tbody>
    {historicoPontos.map((ponto) => {
        const pontosNumericos = Number(ponto.pontos); // Converte para número, se necessário
        return (
            <tr key={ponto.id} className={pontosNumericos < 0 ? 'linha-negativa' : ''}>
                <td>{ponto.nome_evento || ponto.motivo}</td>
                <td>{pontosNumericos}</td>
                <td>{formatarData(ponto.data_coin)}</td>
            </tr>
        );
    })}
</tbody>


                </Table>
            </div>
        </div>
    );
};

export default Mobile;
