import React, { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';

import { Container, ConteudoTitulo, BotaoAcao, ButtonInfo, Titulo, ConteudoProd } from './styles';

export const VisualizarFuncionario = (props) => {

    const [data, setData] = useState([]);

    const [id] = useState(props.match.params.id);

    useEffect(() => {
        const getFuncionario = async () => {
            await fetch("https://timecherie.com.br/api/componentes_funcionario/visualizar.php?id=" + id)
            .then((response) => response.json())
            .then((responseJson) => {
               
               

                    setData(responseJson.funcionario);
                });
        }
        getFuncionario(); 
    }, [id]);
    const getStatusText = (status) => {
        return status == 1 ? ' Ativo' : status == 2 ? ' Inativo' : 'desconhecido';
      };
    return (
        <Container>
            <ConteudoTitulo>
                <Titulo>Visualizar Item</Titulo>
                <BotaoAcao>
                    <Link to="/homefuncionario">
                        <ButtonInfo>Listar</ButtonInfo>
                    </Link>
                </BotaoAcao>
            </ConteudoTitulo>
            <ConteudoProd>ID: {data.id}</ConteudoProd>
            <ConteudoProd>Nome: {data.nome}</ConteudoProd>
            <td>Status:{getStatusText(data.statusbd)}</td>
        </Container>
    );
}