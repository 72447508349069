import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  ConteudoTitulo,
  BotaoAcao,
  ButtonSuccess,
  Table,
  Titulo,
  ButtonPrimary,
  ButtonWarning,
  ButtonDanger,
} from './styles';
import TelaPrincipal from '../../telaprincipal/header';
import './style.css';

export const HomeFuncionario = () => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const getFuncionarios = async () => {
    try {
      const response = await fetch("https://timecherie.com.br/api/componentes_funcionario/index.php");

      if (!response.ok) {
        throw new Error("Falha ao buscar Funcionarios");
      }

      const responseData = await response.json();
      const data = Object.values(responseData.records);

      if (!data || data.length === 0) {
        console.warn("A API retornou uma lista vazia de Funcionarios.");
      }

      const clientesOrdenados = data.sort((a, b) => a.nome.localeCompare(b.nome));
      setData(clientesOrdenados);
    } catch (error) {
      console.error("Erro ao buscar Funcionarios: ", error);
    }
  };
  const formatCpf = (cpf) => {
    if (cpf) {
      return cpf.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
    }
    return cpf;
  };

  useEffect(() => {
    getFuncionarios();
  }, []);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data.filter(funcionario =>
    funcionario.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (funcionario.cpf && funcionario.cpf.includes(searchTerm)) ||
    (funcionario.departamento && funcionario.departamento.includes(searchTerm))
  );

  const getStatusText = (status) => {
    return status == 1 ? 'Ativo' : status == 2 ? 'Inativo' : 'desconhecido';
  };
  return (
    <Container>
      <TelaPrincipal />
      <ConteudoTitulo>
        <BotaoAcao>
          <Link to="/pag">
            <ButtonSuccess>Voltar</ButtonSuccess>
          </Link>
        </BotaoAcao>
        <Titulo>Listar Funcionarios</Titulo>
        <BotaoAcao>
          <Link to="/cadastrarfuncionario">
            <ButtonSuccess>Cadastrar</ButtonSuccess>
          </Link>
        </BotaoAcao>
        
      </ConteudoTitulo>

      <input
        type="text"
        className='busca_funcionario'
        placeholder="Pesquisar Funcionario"
        value={searchTerm}
        onChange={handleSearchTermChange}
      />

      <Table className="tabela-vendas">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nome</th>
            <th>Departamento</th>
            <th>CPF</th>
            <th>Status</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map(funcionario => (
            <tr key={funcionario.id}>
              <td>{funcionario.id}</td>
              <td>{funcionario.nome}</td>
              <td>{funcionario.departamento}</td>
              <td>{formatCpf(funcionario.cpf)}</td>
              <td>{getStatusText(funcionario.statusbd)}</td>
              <td>
                <Link to={"/visualizarfuncionario/" + funcionario.id}>
                  <ButtonPrimary>Visualizar</ButtonPrimary>
                </Link>{" "}
                <Link to={"/editarfuncionario/" + funcionario.id}>
                  <ButtonWarning>Editar</ButtonWarning>
                </Link>{" "}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default HomeFuncionario;
