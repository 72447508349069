import React from 'react';
import { useHistory } from 'react-router-dom';
import Logo from '../telaprincipal/img/logo.png';
import Funcionario from '../telaprincipal/img/grupo.png'
import Evento from '../telaprincipal/img/celebracao.png'
import Premio from '../telaprincipal/img/premiacao.png'
import Historico from '../telaprincipal/img/rolagem.png'
import Unitario from '../telaprincipal/img/premio_unitario.png'
import Sair from '../telaprincipal/img/demissao.png'
import './style.css'; 

const Header = () => {
    const history = useHistory();
    
    // const handlegrafico = () => {
    //     history.push('/grafico');
    // };
    const handlebtn = () => {
        history.push('/historico');
        window.location.reload()
    };

    const handlebtn1 = () => {
        history.push('/homefuncionario');
        window.location.reload()
    };

    const handlebtn2 = () => {
        history.push('/homeevento');
        window.location.reload()
    };
    
    const handlebtn3 = () => {
        history.push('/premiacao');
        window.location.reload()
    };

    const handlebtn4 = () => {
        history.push('/premiacaoindividual');
        window.location.reload()
    };

    const handlehome = () => {
        history.push('/login');
        window.location.reload()
    };
    const handlebtnSair= () => {
        history.push('/login');
        window.location.reload()
    };

    return (
        <header className="header">
            <div className="msb" id="msb">
                <nav className="navbar navbar-default" role="navigation">

                    <div className="navbar-header">
                        <div className="brand-wrapper">

                            <div className="brand-name-wrapper">
                                <a className="navbar-brand" href="#">
                                <img className='logonav' onClick={handlehome} src={Logo} alt="Logo" />
                                </a>
                            </div>

                        </div>

                    </div>
                    <div className="side-menu-container">
                        <div className="button-container">
                            <button className="custom-button" onClick={handlebtn1}><img className='logonav2' onClick={handlebtn1} src={Funcionario} alt="Logo" /><i className="fa fa-dashboard"></i> Funcionario</button>  
                            <button className="custom-button" onClick={handlebtn2}><img className='logonav2' onClick={handlebtn2} src={Evento} alt="Logo" /><i className="fa fa-dashboard"></i> Evento</button>  
                            <button className="custom-button" onClick={handlebtn3}><img className='logonav2' onClick={handlebtn3} src={Premio} alt="Logo" /><i className="fa fa-puzzle-piece"></i> Premiacao</button>
                            <button className="custom-button" onClick={handlebtn4}><img className='logonav2' onClick={handlebtn4} src={Unitario} alt="Logo" /><i className="fa fa-puzzle-piece"></i> Premio Unitario</button>
                            <button className="custom-button" onClick={handlebtn}><img className='logonav2' onClick={handlebtn} src={Historico} alt="Logo" /><i className="fa fa-puzzle-piece"></i> Historico</button>
                            <button className="custom-button" onClick={handlebtnSair}><img className='logonav2' onClick={handlebtnSair} src={Sair} alt="Logo" /><i className="fa fa-puzzle-piece"></i> Sair</button>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
};

export default Header;
