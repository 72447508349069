import React, { useState, useEffect } from 'react';
import TelaPrincipal from '../../src/telaprincipal/header';
import './style.css';
import {
  Container,
  ConteudoTitulo,
  Table,
  Titulo,
} from './styles';

export const Historico = () => {
  const [historico, setHistorico] = useState([]);
  const [ordem, setOrdem] = useState('asc');
  const [colunaOrdenada, setColunaOrdenada] = useState('nome_funcionario');
  const [termoPesquisa, setTermoPesquisa] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://timecherie.com.br/api/componentes_historico/index.php');
        if (!response.ok) {
          throw new Error('Erro ao carregar os dados da API.');
        }
        const data = await response.json();

        const historicoArray = Object.values(data.records);
        const historicoFiltrado = historicoArray.filter((item) =>
          item.nome_funcionario.toLowerCase().includes(termoPesquisa.toLowerCase()) ||
          (item.motivo ? item.motivo.toLowerCase().includes(termoPesquisa.toLowerCase()) : false) ||
          (item.nome_evento ? item.nome_evento.toLowerCase().includes(termoPesquisa.toLowerCase()) : false)
        );

        const historicoOrdenado = historicoFiltrado.sort((a, b) => {
          const valorA = colunaOrdenada === 'data_coin' ? new Date(a[colunaOrdenada]) : a[colunaOrdenada];
          const valorB = colunaOrdenada === 'data_coin' ? new Date(b[colunaOrdenada]) : b[colunaOrdenada];

          if (valorA < valorB) {
            return ordem === 'asc' ? -1 : 1;
          }
          if (valorA > valorB) {
            return ordem === 'asc' ? 1 : -1;
          }
          return 0;
        });

        setHistorico(historicoOrdenado);
      } catch (error) {
        console.error('Erro ao carregar os dados:', error.message);
      }
    };

    fetchData();
  }, [colunaOrdenada, ordem, termoPesquisa]);

  const somaPontos = historico.reduce((total, item) => total + parseInt(item.pontos), 0);

  const handleOrdenar = (coluna) => {
    setColunaOrdenada(coluna);
    setOrdem((ordem) => (ordem === 'asc' ? 'desc' : 'asc'));
  };

  const handlePesquisa = (e) => {
    setTermoPesquisa(e.target.value);
  };

const formatarData = (data) => {
  const dataFormatada = new Date(data);
  const dia = String(dataFormatada.getDate()).padStart(2, '0');
  const mes = String(dataFormatada.getMonth() + 1).padStart(2, '0');
  const ano = dataFormatada.getFullYear();
  return `${dia}/${mes}/${ano}`;
};


  return (
    <div>
      <TelaPrincipal />
      <Container>
        <ConteudoTitulo>
          <Titulo className='titulo2'>Ranking de Pontuação</Titulo>
        </ConteudoTitulo>
        <div className="pesquisa-container">
          <label>Pesquisar:</label>
          <input
          className='inp_historico'
            type="text"
            value={termoPesquisa}
            onChange={handlePesquisa}
          />
        </div>
        <Table>
          <thead>
            <tr>
              <th onClick={() => handleOrdenar('nome_funcionario')}>Nome Funcionário</th>
              <th onClick={() => handleOrdenar('motivo_evento')}>Motivo | Evento</th>
              <th onClick={() => handleOrdenar('pontos')}>Pontos</th>
              <th onClick={() => handleOrdenar('data_coin')}>Data</th>
            </tr>
          </thead>
          <tbody>
            {historico.map((item) => (
              <tr key={item.id_funcionario}>
                <td>{item.nome_funcionario}</td>
                <td>{item.motivo ? item.motivo : item.nome_evento}</td>
                <td>{item.pontos}</td>
                <td>{formatarData(item.data_coin)}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className='soma'>
          <p>Total: {somaPontos} pontos</p>
        </div>
      </Container>
    </div>
  );
};

export default Historico;
