import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import TelaPrincipal from '../../src/telaprincipal/header';
import './style.css'
import {
    Container,
    ConteudoTitulo,
    ButtonSuccess,
    Titulo,
} from './styles';

export const PremiacaoIndividual = () => {
    const history = useHistory();
    const [selectedFuncionario, setSelectedFuncionario] = useState('');
    const [motivo, setMotivo] = useState('');
    const [loadingFuncionarios, setLoadingFuncionarios] = useState(true);
    const [errorFuncionarios, setErrorFuncionarios] = useState(null);
    const [funcionarios, setFuncionarios] = useState([]);
    const [valor, setValor] = useState(0);
    const [totalPontosFuncionario, setTotalPontosFuncionario] = useState(0);

    useEffect(() => {
        carregarFuncionarios();
    }, []);

    const carregarFuncionarios = async () => {
        try {
            const response = await fetch('https://timecherie.com.br/api/componentes_funcionario/index.php');
            if (!response.ok) {
                throw new Error('Erro ao carregar funcionários. Código: ' + response.status);
            }
            const data = await response.json();

            const funcionariosArray = Object.values(data.records);

            setFuncionarios(funcionariosArray);
        } catch (error) {
            setErrorFuncionarios('Erro ao carregar funcionários: ' + error.message);
        } finally {
            setLoadingFuncionarios(false);
        }
    };
    const handleSalvar = async () => {
        try {
            if (!selectedFuncionario || !motivo || valor === 0) {
                alert('Por favor, preencha todos os campos antes de salvar a premiação.');
                return;
            }
    
            // Restante do código...
    
            const responsePontos = await fetch(`https://timecherie.com.br/api/componentes_premiacao/index.php?id_funcionario=${selectedFuncionario}`);
            if (!responsePontos.ok) {
                throw new Error('Erro ao obter total de pontos do funcionário. Código: ' + responsePontos.status);
            }
            const dataPontos = await responsePontos.json();
    
            // Certifique-se de que o retorno possui a estrutura correta
            const totalPontos = dataPontos.total_pontos ? parseInt(dataPontos.total_pontos) : 0;
    
            // Verificar se o valor é negativo apenas quando estiver subtraindo
            if (valor < 0 && (totalPontos + valor) < 0) {
                alert('O colaborador não possui crédito suficiente para esta operação!');
            } else {
                // Restante do código para salvar a premiação...
    
                const response = await fetch('https://timecherie.com.br/api/componentes_premiacao/cadastrar_unitario.php', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        id_funcionario: selectedFuncionario,
                        pontos: valor,
                        motivo: motivo,
                    }),
                });
    
                if (!response.ok) {
                    throw new Error('Erro ao salvar premiação. Código: ' + response.status);
                }
    
                const responseData = await response.json();
    
                if (responseData.erro) {
                    alert('Erro ao salvar premiação: ' + responseData.mensagem);
                } else {
                    alert('Premiação salva com sucesso!');
                    setSelectedFuncionario('');
                    setValor(0);
                    setMotivo('');
                }
            }
        } catch (error) {
            console.error('Erro ao salvar premiação:', error.message);
        }
    };
    

    return (
        <Container>
            <TelaPrincipal />
            <ConteudoTitulo>
                <Titulo className='titulo2'>Premiação Individual</Titulo>
            </ConteudoTitulo>
            <div className='motivo_container'>
                <label >Funcionário:</label>
                <div>
                    <select
                        className='select_funcionario2'
                        value={selectedFuncionario}
                        onChange={(e) => setSelectedFuncionario(e.target.value)}
                    // size={}  tamanho da lista de funcionario
                    >
                        <option value="">Selecione o funcionário</option>
                        {funcionarios.map((funcionario) => (
                            <option key={funcionario.id} value={funcionario.id}>
                                {funcionario.nome}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className='motivo_container'>
                <label>Motivo:</label>
                <div className="textarea-wrapper">
                    <textarea
                        value={motivo}
                        onChange={(e) => setMotivo(e.target.value)}
                        rows={3}
                        cols={64}
                    />
                </div>
            </div>
            <div className='motivo_container'>
                <label>Valor:</label>
                <div>
                    <input
                        type="number"
                        className='valor_container'
                        value={valor}
                        onChange={(e) => setValor(Number(e.target.value))}
                        min="-9999"
                    />
                </div>
            </div>
            <br></br>
            <ButtonSuccess onClick={handleSalvar}>
                Salvar Premiação
            </ButtonSuccess>
        </Container>
    );
};

export default PremiacaoIndividual;
