import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import TelaPrincipal from '../../telaprincipal/header';
import './style.css';
import {
  Container,
  ConteudoTitulo,
  BotaoAcao,
  ButtonSuccess,
  Table,
  Titulo,
  ButtonPrimary,
  ButtonWarning,
  ButtonDanger,
} from './styles';

export const HomeEvento = ({ location }) => {
  const [data, setData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const history = useHistory();

  const getFuncionarios = async () => {
    try {
      const response = await fetch("https://timecherie.com.br/api/componentes_evento/index.php");

      if (!response.ok) {
        throw new Error("Falha ao buscar eventos");
      }

      const responseData = await response.json();
      const data = Object.values(responseData.records);

      if (!data || data.length === 0) {
        console.warn("A API retornou uma lista vazia de eventos.");
      }

      const clientesOrdenados = data.sort((a, b) => a.nome.localeCompare(b.nome));
      setData(clientesOrdenados);
    } catch (error) {
      console.error("Erro ao buscar eventos: ", error);
    }

  };
  const getStatusText = (status) => {
    return status == 1 ? 'Ativo' : status == 2 ? 'Inativo' : 'desconhecido';
  };

  useEffect(() => {
    getFuncionarios();
  }, []);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredData = data.filter(evento =>
    evento.nome.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const formatarData = (data) => {
    const dataObj = new Date(data);
    const dia = dataObj.getDate().toString().padStart(2, '0');
    const mes = (dataObj.getMonth() + 1).toString().padStart(2, '0');
    const ano = dataObj.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  return (
    <Container>
      <TelaPrincipal />
      <ConteudoTitulo>
        <BotaoAcao>
          <Link to="/pag" >
            <ButtonSuccess>Voltar</ButtonSuccess>
          </Link>
        </BotaoAcao>
        <Titulo>Listar Eventos</Titulo>
        <BotaoAcao>
          <Link to="/cadastrarevento" refresh="true">
            <ButtonSuccess>Cadastrar</ButtonSuccess>
          </Link>
        </BotaoAcao>
      </ConteudoTitulo>

      <input
        type="text"
        className='busca_evento'
        placeholder="  Pesquisar Evento"
        value={searchTerm}
        onChange={handleSearchTermChange}
      />

      <Table className="tabela-vendas">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nome</th>
            <th>Data Inicio</th>
            <th>Data Fim</th>
            <th>Pontos</th>
            <th>Status</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody>
          {filteredData.map(evento => (
            <tr key={evento.id}>
              <td>{evento.id}</td>
              <td>{evento.nome}</td>
              <td>{formatarData(evento.data_inicio)}</td>
              <td>{formatarData(evento.data_fim)}</td>
              <td>{evento.pontos}</td>
              <td>{getStatusText(evento.statusbd)}</td>
              <td>
                <Link to={"/visualizarevento/" + evento.id}>
                  <ButtonPrimary>Visualizar</ButtonPrimary>
                </Link>{" "}
                <Link to={"/editarevento/" + evento.id}>
                  <ButtonWarning>Editar</ButtonWarning>
                </Link>{" "}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default HomeEvento;
