import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Container, ConteudoForm, ConteudoTitulo, Titulo, BotaoAcao, ButtonInfo, AlertSuccess, AlertDanger, Form, Label, Input, Select, Option, ButtonWarning } from './styles';

export const EditarEvento = (props) => {

    const [id] = useState(props.match.params.id);
    const [dadosEvento, setDadosEvento] = useState({
        nome: '',
        descricao: '',
        data_inicio: '',
        data_fim: '',
        statusbd: '',
        pontos: ''
    });

    const [status, setStatus] = useState({
        type: '',
        mensagem: ''
    })

    const editEvento = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch("https://timecherie.com.br/api/componentes_evento/editar.php", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ id, ...dadosEvento })
            });

            const responseJson = await response.json();

            if (responseJson.erro) {
                setStatus({
                    type: 'error',
                    mensagem: responseJson.mensagem
                });
            } else {
                setStatus({
                    type: 'success',
                    mensagem: responseJson.mensagem
                });
            }
        } catch (error) {
            setStatus({
                type: 'error',
                mensagem: "Evento não editado com sucesso, tente mais tarde!"
            });
        }
    }

    useEffect(() => {
        const getEvento = async () => {
            await fetch("https://timecherie.com.br/api/componentes_evento/visualizar.php?id=" + id)
                .then((response) => response.json())
                .then((responseJson) => {
                    setDadosEvento(responseJson.evento);
                });
        }
        getEvento();
    }, [id]);

    return (
        <Container>
            <ConteudoForm>
                <ConteudoTitulo>
                    <Titulo>Editar Evento</Titulo>
                    <BotaoAcao>
                        <Link to="/homeevento">
                            <ButtonInfo>Listar</ButtonInfo>
                        </Link>
                    </BotaoAcao>
                </ConteudoTitulo>

                {status.type === 'erro' ? <AlertDanger>{status.mensagem}</AlertDanger> : ""}
                {status.type === 'success' ? <AlertSuccess>{status.mensagem}</AlertSuccess> : ""}

                <Form onSubmit={editEvento}>
                    <Label>Nome: </Label>
                    <Input type="text" name="nome" placeholder="Nome do Evento" value={dadosEvento.nome} onChange={(e) => setDadosEvento({ ...dadosEvento, nome: e.target.value })} />
                    
                    <Label>Descrição: </Label>
                    <Input type="text" name="descricao" placeholder="Descrição do Evento" value={dadosEvento.descricao} onChange={(e) => setDadosEvento({ ...dadosEvento, descricao: e.target.value })} />

                    <Label>Data de Início: </Label>
                    <Input type="text" name="data_inicio" placeholder="Data de Início do Evento" value={dadosEvento.data_inicio} onChange={(e) => setDadosEvento({ ...dadosEvento, data_inicio: e.target.value })} />

                    <Label>Data de Fim: </Label>
                    <Input type="text" name="data_fim" placeholder="Data de Fim do Evento" value={dadosEvento.data_fim} onChange={(e) => setDadosEvento({ ...dadosEvento, data_fim: e.target.value })} />

                    <Label>Status: </Label>

                    <Select name="statusbd" value={dadosEvento.statusbd} onChange={(e) => setDadosEvento({ ...dadosEvento, statusbd: e.target.value })}>
                        <Option value="1">Ativo</Option>
                        <Option value="2">Inativo</Option>
                    </Select>

                    <Label>Pontos: </Label>
                    <Input type="text" name="pontos" placeholder="Pontos do Evento" value={dadosEvento.pontos} onChange={(e) => setDadosEvento({ ...dadosEvento, pontos: e.target.value })} />

                    <ButtonWarning type="submit">Editar</ButtonWarning>
                </Form>
            </ConteudoForm>
        </Container>
    );
}