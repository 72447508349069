import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Logo from '../page_login/logo.png';
import './login.css';

export const LoginFuncionario = () => {
  const [cpf, setCpf] = useState('');
  const [erro, setErro] = useState(null);
  const history = useHistory();

  const handleLogin = () => {

    fetch(`https://timecherie.com.br/api/componentes_login/autenticacao_funcionario.php`, {
      method: 'POST', 
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        cpf: cpf.replace(/\D/g, ''), 
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === 'success') {
          history.push({
            pathname: '/funcionario',
            state: { usuario: data.nome, cpf: data.cpf },
          });
          window.location.reload();
        } else if (data.status === 'error') {
          setErro(data.message);
          setCpf('');
        }
      })
      .catch((error) => {
        console.error('Erro ao autenticar: ', error);
      });
  };

  const formatarCPF = (valor) => {
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  };

  const handleChangeCPF = (e) => {
    setCpf(formatarCPF(e.target.value));
  };

  return (
    <div className='container'>
      <div className="login-page">
        <div className="form">
          <img className='logo-pdv' src={Logo} alt="Logo" />
          <input
          className='inp_cpf'
            type="text"
            placeholder="CPF"
            value={cpf}
            onChange={handleChangeCPF}
          />
          <button onClick={handleLogin}>Login</button>
          {erro && (
            <div className={`error-message ${erro ? '' : 'no-error'}`} role="alert">
              {erro}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginFuncionario;
