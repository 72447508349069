import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Logo from '../page_login/logo.png';
import './login.css';

export const Login = () => {
  const [usuario, setUsuario] = useState('');
  const [senha, setSenha] = useState('');
  const [erro, setErro] = useState(null);
  const history = useHistory();

  const handleLogin = () => {
    const data = {
      usuario: usuario,
      senha: senha
    };

    fetch('https://timecherie.com.br/api/componentes_login/autenticacao.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => response.json())
      .then((data) => {
        localStorage.setItem('usuario', usuario);

        if (data.status === 'success') {
          if (data.tipoUsuario === 'rh') {
            history.push({
              pathname: '/home',
              state: { usuario: data.nome },
            });
            window.location.reload();
          } else if (data.tipoUsuario === 'financeiro') {
            history.push({
              pathname: '/home',
              state: { usuario: data.nome },
            });
          }
        } else if (data.status === 'error') {
          setErro(data.message);
          setUsuario('');
          setSenha('');
        }
      })
      .catch((error) => {
        console.error('Erro ao autenticar: ', error);
      });
  };

  return (
    <div className='container'>
      <div className="login-page">
        <div className="form">
          <img className='logo-pdv' src={Logo} alt="Logo" />
          <input
            type="text"
            placeholder="Nome de Usuário"
            value={usuario}
            onChange={(e) => setUsuario(e.target.value)}
          />
          <input
            type="password"
            placeholder="Senha"
            value={senha}
            onChange={(e) => setSenha(e.target.value)}
          />
          <button onClick={handleLogin}>Login</button>
          {erro && (
            <div className={`error-message ${erro ? '' : 'no-error'}`} role="alert">
              {erro}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
