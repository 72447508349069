import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Pag from '../telaprincipal/header'

export const Home = () => {
  const [usuario, setUsuario] = useState('');

  const storedUsuario = localStorage.getItem('usuario');
  return (
    <Pag />
  );
};

export default Home;
