import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './style.css'
import { Container, ConteudoTitulo, BotaoAcao, ButtonInfo, Titulo, ConteudoProd} from './styles';

export const VisualizarEvento = (props) => {

    const [data, setData] = useState([]);

    const [id] = useState(props.match.params.id);

    useEffect(() => {
        const getEvento = async () => {
            await fetch("https://timecherie.com.br/api/componentes_evento/visualizar.php?id=" + id)
                .then((response) => response.json())
                .then((responseJson) => {
                    setData(responseJson.evento);
                });
        }
        getEvento();
    }, [id]);
    const getStatusText = (status) => {
        return status == 1 ? 'Ativo' : status == 2 ? 'Inativo' : 'desconhecido';
    };

    return (
        <Container>
            <ConteudoTitulo>
                <Titulo>Visualizar Evento</Titulo>
                <BotaoAcao>
                    <Link to="/homeevento">
                        <ButtonInfo>Listar</ButtonInfo>
                    </Link>
                </BotaoAcao>
                
            </ConteudoTitulo>
            <ConteudoProd className='name_visu'>ID: {data.id}</ConteudoProd>
            <ConteudoProd className='name_visu'>Nome: {data.nome}</ConteudoProd>
            <ConteudoProd className='name_visu'>Descrição: {data.descricao}</ConteudoProd>
            <ConteudoProd className='name_visu'>
                Data Início: {data.data_inicio ? new Date(data.data_inicio).toLocaleDateString('pt-BR') : 'Data inválida'}
            </ConteudoProd >
            <ConteudoProd className='name_visu'>
                Data Fim: {data.data_fim ? new Date(data.data_fim).toLocaleDateString('pt-BR') : 'Data inválida'}
            </ConteudoProd> 

            <ConteudoProd className='name_visu'>Status: {getStatusText(data.statusbd)}</ConteudoProd>
            <ConteudoProd className='name_visu'>Pontos: {data.pontos}</ConteudoProd>
        </Container>
    );
}
