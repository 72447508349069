import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import TelaPrincipal from '../../src/telaprincipal/header';
import './style.css'
import {
    Container,
    ConteudoTitulo,
    BotaoAcao,
    ButtonSuccess,
    ButtonWarning,
    Titulo,
    Table,
} from './styles';

export const Premiacao = () => {
    const history = useHistory();
    const [eventos, setEventos] = useState([]);
    const [selectedEvento, setSelectedEvento] = useState('');
    const [selectedFuncionario, setSelectedFuncionario] = useState('');
    const [listaSelecionados, setListaSelecionados] = useState([]);
    const [loadingEventos, setLoadingEventos] = useState(true);
    const [loadingFuncionarios, setLoadingFuncionarios] = useState(true);
    const [errorEventos, setErrorEventos] = useState(null);
    const [errorFuncionarios, setErrorFuncionarios] = useState(null);
    const [funcionarios, setFuncionarios] = useState([]);
    const [eventoSelecionado, setEventoSelecionado] = useState(false);

    useEffect(() => {
        carregarEventos();
        carregarFuncionarios();
    }, []);

    const handleRemoverItem = (index) => {
        const novaLista = [...listaSelecionados];
        novaLista.splice(index, 1);
        setListaSelecionados(novaLista);
    };
    const carregarEventos = async () => {
        try {
            const response = await fetch('https://timecherie.com.br/api/componentes_evento/index.php');
            if (!response.ok) {
                throw new Error('Erro ao carregar eventos. Código: ' + response.status);
            }
            const data = await response.json();

            const eventosArray = Object.values(data.records);

            setEventos(eventosArray);
        } catch (error) {
            setErrorEventos('Erro ao carregar eventos: ' + error.message);
        } finally {
            setLoadingEventos(false);
        }
    };

    const carregarFuncionarios = async () => {
        try {
            const response = await fetch('https://timecherie.com.br/api/componentes_funcionario/index.php');
            if (!response.ok) {
                throw new Error('Erro ao carregar funcionários. Código: ' + response.status);
            }
            const data = await response.json();

            const funcionariosArray = Object.values(data.records);

            setFuncionarios(funcionariosArray);
        } catch (error) {
            setErrorFuncionarios('Erro ao carregar funcionários: ' + error.message);
        } finally {
            setLoadingFuncionarios(false);
        }
    };
    const handleAdicionarLista = () => {
        if (selectedEvento && selectedFuncionario) {
            const eventoSelecionado = eventos.find((evento) => evento.nome === selectedEvento);
            const funcionarioSelecionado = funcionarios.find((funcionario) => funcionario.nome === selectedFuncionario);

            console.log('Selected Evento:', selectedEvento);
            console.log('Selected Funcionario:', selectedFuncionario);
            console.log('Evento Selecionado:', eventoSelecionado);
            console.log('Funcionario Selecionado:', funcionarioSelecionado);

            if (eventoSelecionado && funcionarioSelecionado) {
                const novoItem = {
                    evento: eventoSelecionado.nome,
                    funcionario: funcionarioSelecionado.nome,
                    eventoId: eventoSelecionado.id,
                    funcionarioId: funcionarioSelecionado.id,
                };

                console.log('Novo Item:', novoItem);

                setListaSelecionados((prevLista) => [...prevLista, novoItem]);
                setSelectedFuncionario('');
            } else {
                alert('Evento ou Funcionário não encontrado.');
            }
        }
    };


    const handleSelecionarEvento = (eventoId) => {
        setSelectedEvento(eventoId);
        setEventoSelecionado(true);
    };

    const handleSalvarPremiacao = async () => {
        try {
            const listaParaEnviar = listaSelecionados.map(item => ({
                eventoId: item.eventoId,
                funcionarioId: item.funcionarioId,
            }));

            const response = await fetch('https://timecherie.com.br/api/componentes_premiacao/cadastrar.php', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ listaSelecionados: listaParaEnviar }),
            });

            if (!response.ok) {
                throw new Error('Erro ao salvar premiação. Código: ' + response.status);
            }

            const responseData = await response.json();

            if (responseData.erro) {
                alert('Erro ao salvar premiação: ' + responseData.mensagem);
            } else {
                alert('Premiação salva com sucesso!');
                setListaSelecionados([]);
            }
        } catch (error) {
            console.error('Erro ao salvar premiação:', error.message);
        }
    };


    return (
        <Container>
            <TelaPrincipal />
            <ConteudoTitulo className='titu'>
                <Titulo>Premiação</Titulo>
        
            </ConteudoTitulo>

            <div className='select_evento'>
                <label>Evento:</label>
                <select
                    value={selectedEvento}
                    onChange={(e) => handleSelecionarEvento(e.target.value)}
                    disabled={eventoSelecionado}
                    className='sel_evento'
                >
                    <option value="">Selecione um evento</option>
                    {Array.isArray(eventos) && eventos.map((evento) => (
                        <option key={evento.id} value={evento.nome}>
                            {evento.nome}
                        </option>
                    ))}
                </select>
            </div>

            <div className='select_funcionario'>
                <label>Funcionário:</label>
                <input
                    type="text"
                    value={selectedFuncionario}
                    onChange={(e) => setSelectedFuncionario(e.target.value)}
                    list="funcionarios-list"
                    className='sel_funcionario'
                />
                <datalist id="funcionarios-list">
                    {Array.isArray(funcionarios) &&
                        funcionarios.map((funcionario) => (
                            <option key={funcionario.id} value={funcionario.nome} />
                        ))}
                </datalist>
            </div>

            <ButtonSuccess onClick={handleAdicionarLista} disabled={!eventoSelecionado}>
                Adicionar à Lista
            </ButtonSuccess>
            <div>
                            <br></br>
                <Table className="tabela-vendas">
                    <thead>
                        <tr>
                            <th>Evento</th>
                            <th>Funcionário</th>
                            <th>Açoes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {listaSelecionados.map((item, index) => (
                            <tr key={index}>
                                <td>{item.evento}</td>
                                <td>{item.funcionario}</td>
                                <td>
                                    <button onClick={() => handleRemoverItem(index)}>Remover</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <br></br>
                <ButtonSuccess onClick={handleSalvarPremiacao} disabled={!eventoSelecionado}>
                    Salvar Premiação
                </ButtonSuccess>
            </div>
        </Container>
    );
};

export default Premiacao;
