import React, { Component } from 'react';
import './style.css';
import Header from './header';

class TelaPrincipal extends Component {
  render() {
    return (
      <div className="App">
        <Header />
      </div>
    );
  }
}
export default TelaPrincipal;
