import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { Login } from '../src/page_login/login';
import { Home } from '../src/pages_home/index';
import   Pag  from './telaprincipal/index';
import { HomeFuncionario } from '../src/pages_funcionario/Home/index';
import { CadastrarFuncionario } from './pages_funcionario/Cadastrar/index';
import { VisualizarFuncionario} from './pages_funcionario/Visualizar/index';
import { EditarFuncionario} from './pages_funcionario/Editar/index';

import { HomeEvento } from '../src/pages_evento/Home/index';
import {CadastrarEvento} from './pages_evento/Cadastrar/index';
import {VisualizarEvento} from './pages_evento/Visualizar/index';
import { EditarEvento } from './pages_evento/Editar/index';

import { Premiacao } from './pages_premiacao/index';
import { PremiacaoIndividual } from '../src/pages_individual/index';

import { Historico } from '../src/pages_historico/index';

import { LoginFuncionario } from '../src/page_login_funcionario/login';

import { Mobile } from '../src/page_mobile/index';

function App() {
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/" component={LoginFuncionario} />

          <Route path="/home" component={Home} />
          <Route path="/pag" component={Pag} />
          <Route path="/homefuncionario" component={HomeFuncionario} />
          <Route path="/cadastrarfuncionario" exact component={CadastrarFuncionario} />
          <Route path="/visualizarfuncionario/:id" component={VisualizarFuncionario} />
          <Route path="/editarfuncionario/:id" component={EditarFuncionario} />

          <Route path="/homeevento" component={HomeEvento} />
          <Route path="/cadastrarevento" component={CadastrarEvento} />
          <Route path="/visualizarevento/:id" component={VisualizarEvento} />
          <Route path="/editarevento/:id" component={EditarEvento} />

          <Route path="/premiacao" component={Premiacao} />
          <Route path="/premiacaoindividual" component={PremiacaoIndividual} />

          <Route path="/historico" component={Historico} />

          <Route path="/funcionario" component={Mobile} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;

